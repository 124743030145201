.ReactModal__Overlay
  align-items: center
  background-color: rgba(#000, 0.5)
  display: flex
  flex-direction: column
  inset: 0px
  justify-content: center
  position: fixed
  z-index: 2000

.ReactModal__Content
  background: #fff
  border: 1px solid rgba(#000, .5)
  border-radius: 8px
  box-shadow: 0 0 16px rgba(#000, .25)
  margin: 32px
  min-width: 300px
  outline: none
  overflow: hidden
