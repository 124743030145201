@import bourbon
@import ./_variables
@import bootstrap/scss/functions
@import bootstrap/scss/variables
@import bootstrap/scss/maps
@import bootstrap/scss/mixins
@import bootstrap/scss/utilities
@import bootstrap/scss/helpers
@import bootstrap/scss/utilities/api
@import bootstrap/scss/reboot
@import bootstrap/scss/containers
@import bootstrap/scss/grid
@import react-modern-drawer/dist/index.css
@import ./modal

*
  box-sizing: border-box

html,
body
  width: 100%
  height: 100%
  margin: 0
  padding: 0
  font-display: swap
  font-size: 1em
  font-size: 16px
  color: #333333
  font-family: $font-primary
  font-weight: 300
  line-height: 1.6
  background-color: #f9f9f9
  background-image: url('~assets/images/bg_pattern.png')

body.drawerOpen,
body.drawerOpen #__next
  overflow: hidden

a
  color: $color-link
  text-decoration: none

  &:hover
    color: $color-link-hover

*::selection
  // background: lighten($fc-color-violet-blue, 7%) !important
  // color: darken($fc-color-dark-brown, 25%) !important
  text-shadow: none !important

h1
  // color: darken($fc-color-link-hover, 10%)
  filter: saturate(.5)
  font-size: 2.5rem
  font-weight: normal
  margin: 1em 0
  text-align: center

h2
  margin: 1.5em 0 .75em 0
  font-size: 2rem

h3
  font-size: 1.5rem
  margin: 1.5em 0 1em 0

  &.first-h3
    margin-top: .5em

h4
  font-size: 1.25rem
  margin: 1.5em 0 .5em 0

h5
  margin: 1.5rem 0 .5rem 0

hr
  border-top: 1px solid rgba(#000, .15)
  border-bottom: 1px solid rgba(#fff, 1)

strong
  font-weight: 500

.text-smaller
  font-size: .9em

.text-highlight
  // color: #7c706b
  font-size: 1.1em

.text-secondary
  font-family: $font-secondary
  letter-spacing: .25rem

.text-footnote
  // color: darken($fc-color-brownie-gray, 33%)
  display: block
  font-size: .75rem
  font-style: italic
  margin-bottom: 1em
  margin-top: 0

.EZDrawer
  z-index: 5000

.hide
  background-color: transparent
  display: block
  height: 0
  opacity: 0
  position: absolute
  width: 0
